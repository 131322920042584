import { Layout } from "antd";
// import jwt_decode from "jwt-decode";
import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import Nav from "../components/Nav";
import AdminSidebar from "../components/Sidebar/adminSidebar";
import { getToken } from "../utils";
import { getValueFromLocalStorage } from "../utils/local-storage";
import AccessCheck from "./accessCheck";
import { authVerification, verifyToken } from "../utils/userSessions";
import { Empty } from "antd";

import NotFoundSVG from "../assets/404_error.svg";

const { Content } = Layout;

const Auth = ({
  component: Component,
  history,
  requiredPermissions,
  ...rest
}) => {
  useEffect(async () => {
    if (!getValueFromLocalStorage("token")) {
      window.location.assign("/");
    } else {
      const res = await verifyToken();
      // history.push(`/redirect`);
    }
  }, [history]);

  useEffect(() => {
    authVerification();
  });
  

  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() ? (
          <div>
            <AccessCheck
              alternateChildren={
                <Empty
                  image={NotFoundSVG}
                  className="h-screen"
                  description="404 Not Found"
                  imageStyle={{ display: "flex", justifyContent: "center" }}
                />
              }
              looseCheck={false}
              requiredPermissions={requiredPermissions ?? ["defaultPermission"]}
            >
              <Layout>
                <Layout>
                  <Nav {...props} hideAdmin title={""} />
                </Layout>
                <Layout>
                  <AdminSidebar props={props} />
                  <Layout className="bg-gray-50">
                    <Content className="px-8 py-10 content overflow-y-auto scrollbar overflow-x-hidden mr-4">
                      <Component {...props} />
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </AccessCheck>
          </div>
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

export default withRouter(Auth);
